import {FC, useEffect, useState} from 'react'
import Switch from 'react-bootstrap/esm/Switch';
import { createBrowserHistory } from "history";

import LoginButton from './Auth/LoginButton'
import LogoutButton from './Auth/LogoutButton';
import {Navbar, NavbarBrand,Collapse, Nav, NavbarToggler } from 'reactstrap'
import {Modal} from 'react-bootstrap';
import axios from 'axios'
import { AppData } from './Types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { nounIt } from './FunctionalComponents/HelperComponents/Functions';
import Premium from './FunctionalComponents/Permium/Premium';
type Props = 
{
    isAuthenticated: boolean,
    id: string,
    data:AppData,
    roleId:number | null,
    organisationId: string | null,
    setStyle:Function,
    style: boolean
}

interface Organisation
{
  id: number,
  name: string
}
const NavBar:FC<Props> = ({data, id, isAuthenticated, roleId, setStyle, style}) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [organisation, setOrganisation] = useState<Organisation | null>(null);
    let history = createBrowserHistory(); 

    useEffect(()=>
    {    
        
    },[roleId])
    

    useEffect(()=>
    {

        const setParamsInUrl = (url: string) =>
        {
            history.push({
                pathname: window.location.href.split('?')[0],
                search: url
            }) 
        }

        const  checkOrganisation = (organisations: Organisation[]) =>
            {       
                setOrganisations(organisations)
                
                if(organisations.length > 1)
                {
                    setIsModalOpen(true)
                }
        
                if(organisations.length === 1)
                {
                    setOrganisation(organisations[0])
                    setParamsInUrl(`?appId=${organisations[0].id}`);
                    data.setOrganisationId(organisations[0].id);
                }
        
            }

        const queryParams = new URLSearchParams(window.location.search)
        const organisationId = queryParams.get('appId');
        if(!id){
            return
        }
        axios.get('/api/teacher/organisations/'+id.toString(), data.config)
        .then(async (response)=>
        {            
            let organisations:Organisation[] = response.data      
            setOrganisations(organisations)
            if(organisationId == null)
            {            
                checkOrganisation(organisations)
            }
            else 
            {            
                var organisation = organisations.filter((o)=> o.id === parseInt(organisationId))
        
                if(organisation.length === 0)
                {
                    checkOrganisation(organisations);
                }else
                {
                    setOrganisation(organisation[0])
                }           
    
            }
        }) 
    },[id, data.config, data])
    

    

    const setParamsInUrl = (url: string) =>
        {
            history.push({
                pathname: window.location.href.split('?')[0],
                search: url
            }) 
        }



    

    function toggle() {        
        setIsOpen(!isOpen);
    }
     
    function closeModal() {
        setIsOpen(false);
      }

    function closeAppIdModal() {
        setIsModalOpen(false);
    }

 
  
    function selectOrganisation(o: Organisation)
    {      
        let url = window.location.search;
        const queryParams = new URLSearchParams(url)
        if(queryParams)
        {
            url += '?appId='+o.id.toString();
        }
        else
        {
            url += '&appId='+o.id.toString();
        }

        closeAppIdModal()
        setParamsInUrl(url);
        setOrganisation(o);      
    }


        return(
        <div>
     <Navbar className="container"  light expand="md">
        <NavbarBrand href="/">    
            <div className="">
                <h3 style={{textAlign:'left'}}>OA {organisation? " | " + organisation.name: null}</h3>
            </div>
        </NavbarBrand>
        <NavbarToggler onClick={()=> toggle()} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>         
            
          </Nav>
        
        {/* <Premium data={data}>
            <div className='mr-5'>
            <FontAwesomeIcon icon={faUser} className='mr-2'/>{nounIt(data.user?.given_name??'')}
          </div>

          <div className='row mr-5'>
            <Switch id="test" defaultChecked={style} onClick={() => setStyle()}/>
          </div>
        </Premium> */}
          

          <div>
                {isAuthenticated && roleId && roleId > 1?
                    <div className="m-1" >
                        <a className="btn btn-outline-info" href={'/admin?appId='+ organisation?.id}>Admin</a>
                    </div>
                    
                :null}
          </div>
         
      
          <div>
                {isAuthenticated?
                    <div className="m-1" >
                        <a className="btn btn-outline-info" href={'/register?appId='+organisation?.id}>My Register</a>
                    </div>
                    
                :null}
          </div>

           

          <div>
                {isAuthenticated?
                    <div className="m-1" >
                        <a className="btn btn-outline-info" href={'/history?appId='+organisation?.id}>History</a>
                    </div>
                    
                :null}
          </div>
          <div>
                {!isAuthenticated?
                    <div className="m-1" >
                        <LoginButton/>
                    </div>
                :null}
          </div>

          
         
          <div className="m-1">
                <LogoutButton/>
          </div>


      

        </Collapse>
      </Navbar>

      <Modal show={isModalOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                <Modal.Title>Choose Organisation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    organisations.map((o)=>
                    <div className='mb-3 row mr-5 ml-5'>
                      <div className="col">{o.name}</div><button className='col btn btn-outline-primary' onClick={(e) => selectOrganisation(o)}>sign in</button>
                    </div>
                    )
                   }
                </Modal.Body>
            </Modal>  
        
        </div>)
    

}


export default NavBar