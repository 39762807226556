import { BrowserRouter as Router, Route,Switch} from "react-router-dom";
import './App.css';
import './sass/core.scss'
import 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import InstantLogOut from './components/Auth/InstantLogoutButton';
import { useAuth0 } from "@auth0/auth0-react";
import NavBar from './components/NavBar';
import axios from 'axios'
import { useState } from "react";
import { AuthenticationGuard } from "./Auth0/authentication-guard";
import { getOrganisationIdFromURL, isAdmin, isMSLCUser, isOmega, setDataHelper } from "./components/FunctionalComponents/HelperComponents/Functions";

import { AppData,AppConfig, Organisation } from "./components/Types/types";
import { adminRoutesAll, adminRoutesOaOnly, authedRoutes, omegaRoutes, publicRoutes } from "./Routes/routes";
function isUserAuthenticated(roles:Array<string>, requiredAuth:string)
{
  return requiredAuth === ""? true : roles?.includes(requiredAuth);
}

const getStyleFromLocalStorage = () =>  
  {
    return (localStorage.getItem("oaStyle")??"false") === "true"
  }


const REACT_APP_SERVER = process.env.REACT_APP_SERVER
const REACT_APP_AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE
const POLICY_URL = process.env.NEW_POlICY_URL
axios.defaults.baseURL = REACT_APP_SERVER

 function App() {
  console.warn = console.error = () => {};

  var {user, isAuthenticated, getAccessTokenSilently, logout} = useAuth0();
  const _organisationId = getOrganisationIdFromURL();
  const [organisationId, setOrganisationId] = useState<string | null>(null);
  const[loading, setLoading] = useState<boolean>(true);
  
  var [accessToken, setAccessToken] = useState("")
  var [data, setData] = useState<AppData>({isAuthenticated: isAuthenticated, id:"", organisationId: organisationId, config: {headers:{Authorization:""}, roles:[]}, roleId: null, username: '', setOrganisationId: setOrganisationId, user: null});
  var [id, setId] = useState("")
  const [errorMessage, setErrorMessage] = useState("");
  const [roles, setRoles] = useState<string[]>([]);  
  const [roleId, setRoleId] = useState<number | null>(null);  
  const [style, setStyle] = useState<boolean>(getStyleFromLocalStorage());


  const toggleStyleProp = () =>
  {
    localStorage.setItem("oaStyle", (!style).toString());
    setStyle(!style);
  }





 if(user !== null && user !== undefined) {   
    const _user = user;
    const domain = REACT_APP_AUTH_AUDIENCE;   
 
     const token =  getAccessTokenSilently({
        authorizationParams: {
          audience: `${domain}`     
        },
      });    

     Promise.resolve(token).then(async (value)=>
     {         
      setAccessToken(value)
       var config:AppConfig = await {headers:{Authorization:`Bearer  ${value}`}, roles: roles}   
       data.config = await config
        
          await axios.get(`/api/teacher/email-address/${_user.email}`, config).then( (response)=>
          { 
            if(!response.data)
            {             
              logout()
            }
            else 
            {      
              setRoles(user? user[`${POLICY_URL}/roles`]: [])
              setId(response.data.id)
              data.username = user?.given_name;              
              setRoleId(response.data.roleId)                         
              setData(setDataHelper(data, response.data.id, organisationId, response.data.roleId, user?.given_name, {given_name: user?.given_name, roles:user?.[`${POLICY_URL}/roles`]}  ));             
             
              var orgs:Organisation[] =  response.data.organisations;
                
              var exists = orgs?.map(x => x?.id?.toString()).includes(_organisationId??"")
              if(!exists)
              {
                data.organisationId = null;
                setData(data)
                setOrganisationId(null)
              }else
              {
                setLoading(false)
                setOrganisationId(_organisationId)
              }               
                  
            }        

          })       
    
     })
  }

  return (
    <div className={style? "App-2" : "App"} style={{minHeight:'100%',width:'100%',position:'absolute'}} >
      <div className="nav-container">
       { <NavBar data={data} id={id} isAuthenticated={isAuthenticated} roleId={roleId} organisationId={organisationId} setStyle={toggleStyleProp} style={style}/>}
      </div>

      {
        errorMessage.length > 0?
          <div className="alert alert-danger ml-5 mr-5 mt-1 mb-1 d-flex" role="alert">
            <span>{errorMessage}</span> <span className="ml-auto mr-3 btn" onClick={(e)=> setErrorMessage("")} >&times;</span>
          </div>
        :null
      }
     
      <div>
        <Router>    
          <Switch>
            {
              publicRoutes.map((PublicRoute, index)=>
              (
                <Route exact path={PublicRoute.path} render={()=> (<AuthenticationGuard component={(<PublicRoute.Component data={data} />).type} data={data} isAuthenticated={isAuthenticated} hasRequiredAuth={isUserAuthenticated(roles, "")} />)} />     
              ))
            }

            {/* auth required roots */}

            {
              accessToken.length >0 && organisationId &&  !loading ?
              (
                <div>
                  {
                    authedRoutes.map((AuthedRoute)=>
                    (
                      <Route exact path={AuthedRoute.path} render={()=> (<AuthenticationGuard component={(<AuthedRoute.Component data={data} />).type} data={data} isAuthenticated={isAuthenticated} hasRequiredAuth={isUserAuthenticated(roles, "")} />)} />     
                    ))
                  }

                  <Route exact path='/logout' render={() =>(<InstantLogOut  />)}/>
                  
                  { 
                    isAdmin(roleId) ? 
                      (<>
                        {adminRoutesAll.map((AdminRoute)=>
                        (
                          <Route exact path={AdminRoute.path} render={()=> (<AuthenticationGuard component={(<AdminRoute.Component data={data} />).type} data={data} isAuthenticated={isAuthenticated} hasRequiredAuth={isUserAuthenticated(roles, "")} />)} />     
                        ))}     
                      </>)
                    : null 
                  }
                  
                  { 
                    isMSLCUser(organisationId, roleId) || !isAdmin(roleId)? null :
                      <> 
                        {adminRoutesOaOnly.map((AdminRoute, index)=>
                        (
                          <Route exact path={AdminRoute.path} render={()=> (<AuthenticationGuard component={(<AdminRoute.Component data={data} />).type} data={data} isAuthenticated={isAuthenticated} hasRequiredAuth={isUserAuthenticated(roles, "")} />)} />     
                        ))}   
                                                
                      </>
                  }

                  {
                    isOmega(roleId)?
                      <>
                        {omegaRoutes.map((OmegaRoute)=>
                        (
                          <Route exact path={OmegaRoute.path} render={()=> (<AuthenticationGuard component={(<OmegaRoute.Component data={data} />).type} data={data} isAuthenticated={isAuthenticated} hasRequiredAuth={isUserAuthenticated(roles, "")} />)} />     
                        ))}  
                      </>
                      :null
                  }             
                </div>
              )
              :null
            }
      </Switch>   
    </Router> 
    </div>    
    <script src="https://apis.google.com/js/platform.js" async defer></script>    
    <script src="https://code.jquery.com/jquery-1.12.3.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/0.9.0rc1/jspdf.min.js"></script>
    </div>
    
  );
}

export default App;
