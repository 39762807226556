import  {FC, useState, useEffect} from 'react'
import axios from 'axios'
//import AddUserToOrganisationFrom from './AddUserToOrganisationForm';
import AddOrganisationFrom from './AddOrganisationForm';
import { getOrganisationIdFromURL } from '../../HelperComponents/Functions';

interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

// interface FormData 
// {
//     firstName:string, 
//     lastName:string,
//     email:string
// }

interface Organisation
{
  id: number,
  name: string,
  email: string,
  invoicePlan: string
}

const Organisations:FC<Props> =  ({data}) =>
{
    const organisationId = getOrganisationIdFromURL();
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
 

    useEffect(()=>
    {
        getAllOrganisations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    function getAllOrganisations()
    {
        axios.get('/api/organisation/all', data.config)
        .then(async (response)=>
        {
            let organisations:Organisation[] = response.data     
            setOrganisations(organisations)
        })  
    }

    return (
    <div className='container'>
        <h1 className='mb-3'>Organisations</h1>       
        <div>
            <div className='row mb-5'>
                <div className='col'><AddOrganisationFrom data={data} getOrganisations={getAllOrganisations}/></div>
                <div className='col'><a href={`/omega/organisations/connected-account?appId=${organisationId}`}><button className='btn btn-outline-info'>Connect Account</button></a></div>                
            </div>
        <table className='table table-responsive mt-3'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Invoice plan</th>
                        <th>-</th>
                        <th>-</th>
                    </tr>
                </thead>
                <tbody>
                        {organisations?.map((organisation, index)=>
                        (
                            <tr>
                                <td>{organisation.id}</td>
                                <td>{organisation.name}</td>
                                <td>{organisation.email}</td>
                                <td>{organisation.invoicePlan}</td>
                                
                                <td><a href={'organisations/users?appId='+organisation.id} className='btn btn-outline-danger'>Settings</a></td>
                                <td></td>                          
                                <td></td>
                            </tr>
                        ))}
                </tbody>
            </table>           
        </div>
    </div>)
}


export default Organisations