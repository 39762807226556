import {FC, useState, useEffect} from 'react'
import axios from 'axios'
import {Modal, Button} from 'react-bootstrap';
import Messages from '../../HelperComponents/Messages'
import { createBrowserHistory } from "history";
import AddUserButton from './AddUserButton';
 

interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface User 
{
    id: number,
    firstName: string,
    lastName: string
}

const OrganisationSettings:FC<Props> =  ({data}) =>
{

   

    const [errors, setErrors] = useState<string[]>([])
    const [organisationId, setOrganisationId] = useState<number | null>(null);

    const [users, setUsers] = useState<User[]>([]);
    const [user, setUser] = useState<User | null>(null);

    //modal
    const [isOpen, setIsOpen] = useState<boolean>(false);
    function openModal(index:number) {    
        setUser(users[index]);
        setIsOpen(true);
      }
    
    function closeModal() {
        setIsOpen(false);
    }

    function removeUser()
    {
        let errors = []
        
        if(user == null)
        {
            errors.push('user is null')            
        }

        if(organisationId == null)
        {
            errors.push('organisation is null')            
        }

        if(errors.length > 0)
        {
            setErrors(errors)
            return
        }
        
        axios.delete('/api/organisation/user/'+ organisationId +'/'+user?.id, data.config)
        closeModal()
    }

    useEffect(()=>
    {         //query params
        const queryParams = new URLSearchParams(window.location.search)
        const organisationId = queryParams.get('appId');
        let history = createBrowserHistory();  
        if(organisationId != null && Number(organisationId))
        {
            setOrganisationId(parseInt(organisationId))
        }else 
        {
            history.push({
                pathname: '/',
                search: ''
                })
                window.location.reload()
        }
        
        axios.get('/api/organisation/users/'+organisationId, data.config)
        .then(async (response)=>
        {
            let users:User[] = response.data      
            setUsers(users)
        })  
    },[data.config]);

    return (
    <div className='container'>
        <h1 className='mb-5'>Organisation Settings</h1>       
        <div className='row mt-3'>
            <div className='col-3'>
                <AddUserButton data={data}/>
            </div>
            <div className='col'>
                <table className='table table-responsive mt-3'>
                    <thead>
                        <tr>
                            <th>User Id</th>
                            <th>Name</th>
                            <th>Surname</th>
                            <th>-</th>
                        </tr>
                    </thead>
                    <tbody>
                            {users?.map((user, index)=>
                            (
                                <tr>
                                    <td>{user.id}</td>
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td><button className='btn btn-outline-primary' onClick={(e)=>openModal(index)}>Remove User</button></td>                             
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
                 
        </div>
        <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                <Modal.Title>Remove User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Messages messages={errors} />     
                    Are you sure you want to remove {user?.firstName + ' ' +  user?.lastName} from {organisationId}?
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={removeUser}>
                    Remove User
                </Button>
                </Modal.Footer>
        </Modal>
      
    </div>)
}


export default OrganisationSettings