import {FC, useState, useEffect} from 'react'
import DatePicker from "react-datepicker";
import axios from 'axios'
import Messages from '../HelperComponents/Messages'
import { getOrganisationIdFromURL, roundToTwoDecimals, moneyValidation, getPriceMSLC} from '../HelperComponents/Functions';
import Days from '../../Enums/Days'
import { Data } from '../../Types/types';
import Commissions from '../../Enums/Commissions';
interface Props 
{
    studentId: string,
    lessonId:string,
    data:Data,
    student:Student,
    closeModal:Function,
    isLegacyClient:boolean
}

interface Student 
{
    id:number
    firstName:string,
    lastName:string,
    status:string
}

interface Teacher 
{
    id:string,
    firstName:string,
    lastName:string
}

interface Subject 
{
    id: number,
    subject:string
}

interface YearGroup 
{
    id: number,
    yearGroup:string
}

interface FormData 
{
    id:string,
    studentId:string | null,
    teacherId:string | null,
    subject:number | null,
    yearGroup:number | null,
    singleFee:string | null,
    singlePay:string | null,
    groupFee:string | null,
    groupPay:string | null,
    startDate:Date | null,
    endDate:Date | null,
    organisationId: string | null,
    day:number,
    lessonType: number | null
    
}

interface LessonFormDto
{
    studentId:string | null,
    teacherId:string,
    subject:string,
    yearGroup:string,
    singleFee:string,
    singlePay:string,
    groupFee:string,
    groupPay:string,
    startDate:Date | null,
    endDate:Date | null,
    day:number,
    lessonType: number
}



const EditLessonForm:FC<Props> = ({studentId, student, lessonId, data, closeModal, isLegacyClient}) => 
{
    const organisationId = getOrganisationIdFromURL();
    //const [student, setStudent] = useState<Student|null>(null);
    const [teachers, setTeachers] = useState<Teacher[]|null>(null);
    const [subjects, setSubjects] = useState<Subject[]| null>(null);
    const [yeargroups, setYearGroups] = useState<YearGroup[]| null>(null);
    const [lesson, setLesson] = useState<LessonFormDto|null>(null);

    const [teacherId, setTeacherId] = useState<string|null>(null);
    const [subjectId, setSubjectId] = useState<string|null>(null);
    const [yearGroupId, setYearGroupId] = useState<string|null>(null);
    const [lessonGroupTypeId, setLessonGroupTypeId] = useState<number|null>(0);
    var [singleFee, setSingleFee] = useState<string|null>(null);
    var [singlePay, setSinglePay] = useState<string|null>(null);
    var [groupFee, setGroupFee] = useState<string|null>(null);
    var [groupPay, setGroupPay] = useState<string|null>(null);
    const [startDate, setStartDate] = useState<Date|null>(null);
    const [endDate, setEndDate] = useState<Date|null>(null);
    const [day, setDay] = useState<number>(0);

    const [lessonPrice, setLessonPrice] = useState<string| null>(null);
    const [lessonCommission, setLessonCommission] = useState<string| null>(null);

    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([])

    useEffect(()=>    
    {   getTeachers();  
        getSubjects();
        getYearGroups();      
        getLesson(lessonId)
         
    },[])

    useEffect(()=>
    {
       updateLessonPrice()
    },[yearGroupId, subjectId, lessonGroupTypeId])

    const updateLessonPrice = () =>
    {
        let yearGroup = yeargroups?.find(x => x.id.toString() === yearGroupId);
        let lessonType = lessonGroupTypeId?.toString() === "0" ? "G" : "O";
        let subject = subjects?.find(x => x.id.toString() === subjectId);

        let price = getPriceMSLC(yearGroup?.yearGroup??'', subject?.subject??'', lessonType);  
        let commission = getPriceMSLC(yearGroup?.yearGroup??'', subject?.subject??'', lessonType, true);     
            
        if((price === null || price === undefined) && yearGroupId == null && subjectId == null && lessonGroupTypeId == null)
        {
            alert("Pricing error");
            return;
        }
        
        if(price)
        {
            setLessonPrice(price);
            setLessonCommission(commission);
        }        
    } 


    const clearForm = () =>
    {
        closeModal();
        setTeacherId("");
        setSubjectId("");
        setYearGroupId("");
        setSingleFee("0");
        setSinglePay("0");
        setGroupFee("0");
        setGroupPay("0");
        setStartDate(null);
        setEndDate(null);
    }
    
    const getLesson = (id:string) =>
    {
        axios.get('/api/lesson/'+id, data.config)
        .then((response)=>
        {   var lesson:LessonFormDto = response.data
            if(lesson != null)
            {
                if(lesson.startDate === null || lesson.endDate === null)
                {
                    return
                }
                setLesson(lesson);
                setLessonPrice(lesson.groupFee)
                setTeacherId(lesson.teacherId);
                setSubjectId(lesson.subject.toString());
                setYearGroupId(lesson.yearGroup.toString());
                setSingleFee(lesson.singleFee?.toString());
                setSinglePay(lesson.singlePay?.toString());
                setGroupFee(lesson.groupFee.toString());
                setGroupPay(lesson.groupPay.toString());
                setLessonGroupTypeId(lesson.lessonType)              
                setStartDate(new Date(lesson.startDate));
                setEndDate(new Date(lesson.endDate)); 
                updateLessonPrice()  
                setDay(lesson.day)             
            }         
            
        })
    }

    const getTeachers = () =>
    {
         axios.get(`/api/teachers/${organisationId}`, data.config)
         .then((response)=>
         {            
             setTeachers(response.data);
         })
    }

    const getSubjects = () =>
    {
         axios.get('/api/lesson/subjects', data.config)
         .then((response)=>
         {            
             setSubjects(response.data);
         })
    }

    const getYearGroups = () =>
    {
         axios.get('/api/lesson/yeargroups', data.config)
         .then((response)=>
         {            
             setYearGroups(response.data);
         })
    }

    const submit = async () => 
    {
        var currentErrors = []
        if(studentId === null || studentId.length === 0)
        {
            currentErrors.push("Invalid Student Id")
        }

        if(student === null || student?.id === null)
        {
            currentErrors.push("Student does not exist")
        }

        if(teacherId === null || teacherId.length === 0)
        {
            currentErrors.push("Teacher not chosen")
        }

        if(subjectId === null || subjectId.length === 0)
        {
            currentErrors.push("Subject not chosen")
        }

        if(yearGroupId === null || yearGroupId.length === 0)
        {
            currentErrors.push("Year group not chosen")
        }

        if(singleFee === null|| isNaN(Number(singleFee)))
        {
            currentErrors.push(isLegacyClient?"Please enter fee adj": "Please enter single fee")
        }

        if(singlePay === null || isNaN(Number(singlePay)))
        {
            currentErrors.push(isLegacyClient?"Please enter pay adj": "Please enter single pay")
        }

        if(isLegacyClient)
        {
            let yearGroup = yeargroups?.find(x => x.id.toString() === yearGroupId);
            let lessonType = lessonGroupTypeId?.toString() === "0" ? "G" : "O";
            let subject = subjects?.find(x => x.id.toString() === subjectId)

            let price = getPriceMSLC(yearGroup?.yearGroup??'', subject?.subject??'', lessonType);     
            
            if(price === null || price === undefined)
            {
                currentErrors.push("Pricing error")
            }

            
            setLessonPrice(price);
            let feeAdj = moneyValidation(singleFee)
            let payAdj = moneyValidation(singlePay)

            if(price === null || price === undefined)
            {
                currentErrors.push("price issue")
            }
            if(lessonGroupTypeId === null)
            {
                currentErrors.push("Please enter lesson group type")
            }
            
            singleFee = moneyValidation(singleFee).toString();
            singlePay = moneyValidation(singlePay).toString();

            groupFee = moneyValidation(price + feeAdj).toString();
            groupPay = roundToTwoDecimals((getPriceMSLC(yearGroup?.yearGroup??'', subject?.subject??'', lessonType, true))+payAdj).toString()

        }

        if(groupFee === null)
        {
            currentErrors.push("Please enter group fee")
        }

        if(groupPay === null)
        {
            currentErrors.push("Please enter group pay")
        }

        if(startDate === null)
        {
            currentErrors.push("Please enter start date")
        }

        if(endDate === null)
        {
            currentErrors.push("Please enter end date")
        }

        if(organisationId === null)
        {
            currentErrors.push("organisation not set")            
        }

        var dataJSON:FormData =
        {
            id:lessonId,
            studentId: studentId,
            teacherId: teacherId,
            subject:parseInt(subjectId??"0"),
            yearGroup:parseInt(yearGroupId??"0"),
            singleFee:singleFee,
            singlePay:singlePay,
            groupFee:groupFee,
            groupPay:groupPay,
            startDate:startDate,
            endDate:endDate,
            organisationId: organisationId,
            day:day,
            lessonType:lessonGroupTypeId
        }
        setErrors(currentErrors)
        if(currentErrors.length > 0)
        {
            return
        }

        await axios.post('/api/lesson/edit',dataJSON, data.config)
        .then((response)=>
        {
            //getParents()
            clearForm()
            setMessages(["Lesson Saved"])

            setTimeout(()=>
            {
                setMessages([])
               
            }, 3000)
        })      
    }

    return(
        student && lesson?
        <div id="EditLessonForm" className='mt-5'>
            <Messages messages={errors} />     
            <Messages messages={messages} />
                    <div className='form-group'>
                        <label className='label'>Student</label>
                        <div><strong>{studentId} - {student.firstName} - {student.lastName}</strong></div>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Student Id</label>
                        <div>{studentId}</div>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Teacher</label>
                        <select value={teacherId?.toString()} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setTeacherId(e.currentTarget.value) }>
                        <option value={""}>None</option>
                            {teachers?.map((teacher, index)=>
                            (
                                <option value={teacher.id}>{teacher.firstName}</option>
                            ))}                            
                        </select>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Subject</label>
                        <select value={subjectId?.toString()} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setSubjectId(e.currentTarget.value) }>
                            <option value={""}>None</option>
                            {subjects?.map((subject, index)=>
                            (
                                <option value={subject.id}>{subject.subject}</option>
                            ))}                            
                        </select>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Year Group</label>
                        <select value={yearGroupId?.toString()} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setYearGroupId(e.currentTarget.value) }>
                            <option value={""}>None</option>
                            {yeargroups?.map((yearGroup, index)=>
                            (
                                <option value={yearGroup.id}>{yearGroup.yearGroup}</option>
                            ))}                            
                        </select>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Day</label>
                        <select value={day} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setDay(parseInt(e.currentTarget.value)) }>
                            <option value={""}>None</option>
                            {Days?.map((day, index)=>
                            (
                                <option value={index}>{day}</option>
                            ))}                            
                        </select>
                    </div>
                    {
                        isLegacyClient?
                            <div className='form-group'>
                                <label className='label'>Lesson Group Type</label>
                                <select value={lessonGroupTypeId??0} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setLessonGroupTypeId(parseInt(e.currentTarget.value)) }>
                               
                                    <option value={0}>{"Group"}</option>
                                    <option value={1}>{"1 to 1"}</option>                           
                                </select>
                            </div>
                        : null
                    }
                     {
                        isLegacyClient? 
                            <div className='mb-3'>
                                <div>Fee: £{moneyValidation(lessonPrice)}</div>
                                <div>Pay: £{moneyValidation(lessonCommission)}</div>
                            </div>  
                        :
                        <div>
                            <div className='form-group'>
                                <label className='label'>Group Fee</label>
                                <input value={groupFee?groupFee:""}  onChange={(e:React.FormEvent<HTMLInputElement>)=>setGroupFee((e.currentTarget.value))} type="text"  />
                            </div>
                            <div className='form-group'>
                                <label className='label'>Group Pay</label>
                                <input value={groupPay?groupPay:""} onChange={(e:React.FormEvent<HTMLInputElement>)=>setGroupPay((e.currentTarget.value))} type="text"/>
                            </div>
                        </div> 
                    }                   
                
                    <div className='form-group'>
                        <label className='label'>{isLegacyClient? "Fee Adjustment" : "Single Fee"}</label>
                        <input value={singleFee?singleFee:""}   onChange={(e:React.FormEvent<HTMLInputElement>)=>setSingleFee((e.currentTarget.value))} type='text'/>
                    </div>
                    <div className='form-group'>
                        <label className='label'>{isLegacyClient? "Pay Adjustment" : "Single Pay"}</label>
                        <input value={singlePay?singlePay:""}  onChange={(e:React.FormEvent<HTMLInputElement>)=>setSinglePay((e.currentTarget.value))} type="text" />
                    </div>
                    <div className='form-group'>
                        <label className='label'>Start Date</label>
                        <DatePicker 
                            placeholderText='Select date' 
                            dateFormat={'dd/MM/yyyy'}
                            onChange={date => setStartDate(date)}
                            selected={startDate}/>
                    </div>
                    <div className='form-group'>
                        <label className='label'>End Date</label>
                        <DatePicker 
                            placeholderText='Select date' 
                            dateFormat={'dd/MM/yyyy'}
                            onChange={date => setEndDate(date)}
                            selected={endDate}/>
                    </div>
                    <div className='mt-2'>
                        <button className='btn btn-success' onClick={submit}>Save</button>
                    </div>


                    <hr/>
                   
                    
                </div>
    :null
                            )

}


export default EditLessonForm;