import InvoicePlans from "../../Enums/InvoicePlans";
import Organisations from '../../Enums/Organisations';
import { AppData, TakenLesson } from "../../Types/types";
import Prices from "../../Enums/Prices";
import Commissions from "../../Enums/Commissions";

interface AdjustmentLesson 
{
    teacher: string,
    student: string,
    fee: number,
    pay: number
}

const {Parser} = require('json2csv')
 export const  formatDate = (date:Date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const  isExpired = (date:Date) => {

  return new Date(date) < new Date()
}

export const copyToClipBoard = (text: string) => {
  alert('copied')
  navigator.clipboard.writeText(text);
}

export const  formatDateDDMMYYYY = (date:Date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join('/');
}

export const getPrevMonth = (currentMonth: number) => { //Jan = 1

  if(currentMonth === 1)
  {
    return 12
  }

  return currentMonth - 1;
}

export const getPrevYear = (currentMonth: number, currentYear: number) => { //Jan = 1

  if(currentMonth === 1)
  {
    return currentYear - 1
  }

  return currentYear;
}

export const getPrevMonthForRegister = (currentMonth: number) => { //Jan = 1

  if(currentMonth === 0)
  {
    return 11
  }

  return currentMonth - 1;
}

export const getPrevYearForRegister = (currentMonth: number, currentYear: number) => { //Jan = 1

  if(currentMonth === 0)
  {
    return currentYear - 1
  }

  return currentYear;
}

export const getNextMonthForRegister = (currentMonth: number) => { //Jan = 1

  if(currentMonth === 11)
  {
    return 1
  }

  return currentMonth + 1;
}

export const getNextYearForRegister = (currentMonth: number, currentYear: number) => { //Jan = 1

  if(currentMonth === 11)
  {
    return currentYear + 1
  }

  return currentYear;
}


export function getStartDateForRegister(today: Date, isReport: boolean = false) {
  let day = today.getDate();
  var dayLimit = isReport? 28 : 25

  if(!isReport && today <= new Date(2024, 8, 25)) //prices changed after septemper 1st, remove after 26th sep 24
  {
    return new Date(2024, 8, 1);
  }
  if(day > dayLimit)
  {
    //26th of this month
    return new Date(today.getFullYear(), today.getMonth(), 26)
  } else {
    //26th of last month
    return (new Date(getPrevYearForRegister(today.getMonth(), today.getFullYear()), getPrevMonthForRegister(today.getMonth()), 26))
  }
}

export function getEndDateForRegister(today: Date, isReport: boolean = false) {
  let day = today.getDate();
  var dayLimit = isReport? 28 : 25
  if(day > dayLimit)
  {
    //26th of next month
    return (new Date(getNextYearForRegister(today.getMonth(), today.getFullYear()), getNextMonthForRegister(today.getMonth()), 25))
    
  } else {
    //26th of this month
    return new Date(today.getFullYear(), today.getMonth(), 25)
  }
}


export const getOrganisationIdFromURL = () =>
{
    const queryParams = new URLSearchParams(window.location.search)
    const organisationId = queryParams.get('appId');

    if(organisationId != null && Number(organisationId))
    {
        return organisationId;
    }
    else 
    {
        return null
    }
}

export const roundToTwoDecimals = (num: number) =>
{
    return Math.round((num + Number.EPSILON) * 100) / 100
}

export const getPlanId = (name: string) => 
{
    return InvoicePlans.filter(x => x.name === name)[0]?.id?? null
}

export const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^[a-z0-9](\.?[a-z0-9]){5,}@g(oogle)?mail\.com$/
      );
  };

  export const validateParentEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  export function isMSLCUser(organisationId: string | null, roleId: number | null)
  {
    return organisationId === Organisations.MSLC && roleId && roleId < 3;
  }
  
  export function isAdmin(roleId: number | null)
  {
    return roleId && roleId > 1
  }
  
  export function isOmega(roleId: number | null)
  {
    return roleId && roleId === 3
  }

  
export const downloadResource =  (fileName:string, fields = {}, data:any[] | AdjustmentLesson[] | null) => {
  
    //const navigator = window.navigator;
    const json2csv =  new Parser();

    const csvFile =  json2csv.parse(data);

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
   
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }   
    
}

export function showErrorResponse(code:string, response:string)
{
  alert("Error: "+ code.toString() + " " + response.substring(0, 100))
}

export function roundPay(pay:number)
{
  return Math.round((pay + Number.EPSILON) * 100) / 100
}


export function moneyValidation(money:string | null)
{
  var number = Number(money)
  if(isNaN(number))
  {
    return 0
  }
  return roundToTwoDecimals(number);
}

export function setDataHelper(data:AppData, id:string, organisationId:string | null, roleId: any, username:string | undefined, user:any)
{
  
  const model:AppData = data
  model.id = id;
  model.organisationId = organisationId;
  model.roleId = roleId;
  model.username = username;
  model.user = user;
  return model;
}

export function getLessonPriceMSLC(lesson :TakenLesson, type: number, isCommission: boolean = false)
{
  let rateKey = getRateKeyUsingLesson(lesson, type);
  
  return isCommission? Commissions[rateKey] : Prices[rateKey]; 
}

export function getPriceMSLC(yearGroup: string, subject: string, type: string, isCommission: boolean = false)
{
  let rateKey =  getRateKey(yearGroup, subject, type);
  return isCommission? Commissions[rateKey] : Prices[rateKey]; 
}

export function getRateKeyUsingLesson(lesson :TakenLesson, type: number)
{
  let rateKey = ""
  if(type === 1)
  {
    rateKey = lesson.yearGroup?.toUpperCase().charAt(0) + "O" + lesson.subjectText.toUpperCase().charAt(0);
  
  }
  else 
  {
    rateKey = lesson.yearGroup?.toUpperCase().charAt(0) + "G" + lesson.subjectText.toUpperCase().charAt(0);
  }
  
  return rateKey;
}

export function getRateKey(yearGroup: string, subject: string, type: string)
{
  return yearGroup.charAt(0) + type + subject.charAt(0);
}

export function nounIt(text:string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}


