import {FC, useEffect, useState} from 'react'
import axios from 'axios';
import DatePicker from "react-datepicker";
import Messages from '../HelperComponents/Messages'
import {copyToClipBoard, formatDate, getOrganisationIdFromURL, isExpired} from '../HelperComponents/Functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { Props, Invoice } from '../../Types/types';

const InvoicePanel:FC<Props> = ({data}) =>
{
    const organisationId = getOrganisationIdFromURL();
    const [fromDate, setFromDate] = useState<Date | null>(new Date())
    const [toDate, setToDate] = useState<Date | null>(new Date())
    const [invoiceId, setInvoiceId] = useState<number | null>(null)
    const [parentId, setParentId] = useState<number | null>(null)
    const [stripeInvoiceId, setStripeInvoiceId] = useState<string | null>(null)
    const [page, setPage] = useState<number>(1)


    const [errors, setErrors] = useState<string[]>([])
    const [messages] = useState<string[]>([])

    const [invoices, setInvoices] = useState<Invoice[] | null>(null)

    const setPagination = (page:number) =>
    {
        setPage(page);
        submit(page)
    }

    useEffect(()=>
    {
        
    },[])

    const renwewInvoice = (id:number) => 
    {
        var currentErrors = []
        if(id === null)
        {
            currentErrors.push("error")
        }

        setErrors(currentErrors)

        if(currentErrors.length > 0)
        {
            return
        }

        var baseUrl = "/api/invoice/renew/"+id       
        
        axios.get(baseUrl, data.config).then(response=>{submit(page)})
    }

    const submit = (pageNumber:number) => 
    {
        var currentErrors = []
        if(fromDate === null)
        {
            currentErrors.push("Enter From Date")
        }

        if(toDate === null)
        {
            currentErrors.push("Enter To Date")
        }

        if(pageNumber === null || pageNumber <= 0)
        {
            currentErrors.push("Page number error")
        }

        setErrors(currentErrors)

        if(currentErrors.length > 0)
        {
            return
        }

        var baseUrl = "/api/invoice/search?"
        
        if(fromDate != null)
        {
            baseUrl += `fromDate=${formatDate(fromDate)}&`
        }

        if(toDate != null)
        {
            baseUrl += `toDate=${formatDate(toDate)}&`            
        }

        if(invoiceId != null && invoiceId > 0)
        {
            baseUrl += `invoiceId=${invoiceId}&`            
        }

        if(parentId != null && parentId > 0)
        {
            baseUrl += `parentId=${parentId}&`            
        }

        if(stripeInvoiceId != null)
        {
            baseUrl += `stripeInvoiceId=${stripeInvoiceId}&`            
        }

        if(organisationId != null)
        {
            baseUrl += `organisationId=${organisationId}&`            
        }

        if(pageNumber >= 0)
        {
            baseUrl += `page=${pageNumber}&`   
        }

        axios.get(baseUrl, data.config)
                .then(response=>{
                    var data = response.data
                    setInvoices(data.invoices)   
                })

    }
    return(
    <div className='mb-3 container card-body dashboard-card'>
        <h2>Invoice Panel</h2>
        <Messages messages={errors} />     
        <Messages messages={messages} />
        <div className='mt-3'>            
                <p className='mb-3'>(invoices are filtered on created date)</p>
                    <div className='row mt-3 mb-3'>                    
                        <div className='col'>                            
                            <div className='form-group'>                               
                                <DatePicker 
                                        placeholderText='Select date' 
                                        dateFormat={'dd/MM/yyyy'}
                                        onChange={date => setFromDate(date)}
                                        selected={fromDate}/>
                            </div>                            
                        </div>
                        <div className='col'>
                            <div className='form-group'>                               
                                <DatePicker 
                                        placeholderText='Select date' 
                                        dateFormat={'dd/MM/yyyy'}
                                        onChange={date => setToDate(date)}
                                        selected={toDate}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>                        
                        <div className='col-4'>
                            <div className='form-group'>
                                <label className='label'>Invoice Id</label>
                                <input type="number" onChange={(e:React.FormEvent<HTMLInputElement>)=>setInvoiceId(parseInt(e.currentTarget.value))}/>
                            </div>
                            
                        </div>
                        <div className='col-4'>
                        <div className='form-group'>
                                <label className='label'>Parent Id</label>
                                <input type="number" onChange={(e:React.FormEvent<HTMLInputElement>)=>setParentId(parseInt(e.currentTarget.value))}/>
                            </div>                            
                        </div>
                        <div className='col-4'>
                            <div className='form-group'>
                                <label className='label'>Stripe Invoice Id</label>
                                <input type="text" onChange={(e:React.FormEvent<HTMLInputElement>)=>setStripeInvoiceId((e.currentTarget.value))}/>
                            </div>
                        </div>                 
                    </div>
                    <div className='mt-3 mb-3'>
                            <button className='btn btn-outline-success' onClick={()=>submit(1)}>Submit</button>
                        </div>
              
            
        </div>
        <div className='mb-3'>
            {page <= 1? null : <button className='btn btn-primary mr-3' onClick={()=> setPagination(page <= 1? 1: page -1)}>prev</button>}
            {invoices && invoices.length === 0? null : <button className='btn btn-primary' onClick={()=> setPagination(page+1)}>next</button>}
        </div>
        <table className='table table-responsive'>
            <thead>
                <tr>
                    <th>
                        ID
                    </th>
                    <th>
                        Parent
                    </th>
                    <th>
                        S Id
                    </th>
                    <th>
                        Total
                    </th>
                    <th>
                        Fee
                    </th>
                    <th>
                        Net
                    </th>
                    <th>
                        Lessons
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Expriy Date
                    </th>
                    <th>
                        URL
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    invoices?.map((invoice, index)=>
                    (
                        <tr>
                            <td>
                                {invoice.id}
                            </td>
                            <td>
                                {invoice.parentName + " ("+invoice.parentId+")"}
                            </td>
                            <td>
                              <button className='btn btn-outline-primary' onClick={()=>{copyToClipBoard(invoice.stripeInvoiceId)}}><FontAwesomeIcon icon={faCopy}/></button>
                            </td>
                            <td>
                                {"£"+invoice.invoiceAmount}
                            </td>
                            <td>
                                {"£"+invoice.fee}
                            </td>
                            <td>
                                {"£"+(invoice.invoiceAmount - invoice.fee)}
                            </td>
                            <td>
                                <a 
                                    href={"/admin/taken-lesson?appId="+organisationId+"&invoiceId="+invoice.id + 
                                        (invoice.stripeInvoiceId? "&sid="+invoice.stripeInvoiceId : null)} 
                                    className='btn btn-outline-warning'>+</a>
                            </td>
                            <td>
                                {invoice.status}
                            </td>
                            <td>
                                {invoice.expiryDate? formatDate(invoice.expiryDate) + " " + (isExpired(invoice.expiryDate)? "(expired)":"") : "none set"}
                            </td>
                            <td>
                                <a target='_blank' rel="noreferrer" href={invoice.url}><FontAwesomeIcon icon={faCopy}/></a>
                            </td>
                            <td>
                                <button disabled={!isExpired(invoice.expiryDate)} onClick={()=> renwewInvoice(invoice.id)} className='btn btn-outline-danger'>Refresh</button>
                            </td>                              
                        </tr>

                    ))
                }
            </tbody>
        </table>


    </div>)
}


export default InvoicePanel