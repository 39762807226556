import  {FC, useState, useEffect} from 'react'
import axios from 'axios'
import {Modal, Button} from 'react-bootstrap';
import AddUserToOrganisationFrom from './AddUserToOrganisationForm';
//import AddOrganisationFrom from './AddOrganisationForm';
//import { getOrganisationIdFromURL } from '../../HelperComponents/Functions';

interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface Organisation
{
  id: number,
  name: string,
  email: string,
  invoicePlan: string
}

const AddUserButton:FC<Props> =  ({data}) =>
{
    //const organisationId = getOrganisationIdFromURL();
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [organisationIndex, setOrganisationIndex] = useState<number>(0);
    const [organisation, setOrganisation] = useState<Organisation | null>(null);

    //modal
    const [isOpen, setIsOpen] = useState<boolean>(false);
    function openModal(index:number) {
        setOrganisation(organisations[index]);
        setOrganisationIndex(index);        
        setIsOpen(true);
      }
    
    function closeModal() {
        setIsOpen(false);
    }

    useEffect(()=>
    {
        getAllOrganisations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    function getAllOrganisations()
    {
        axios.get('/api/organisation/all', data.config)
        .then(async (response)=>
        {
            let organisations:Organisation[] = response.data      
            setOrganisations(organisations)
        })  
    }

    return (
    <div>
        <button className='btn btn-outline-primary' onClick={()=> openModal(1)}>Add User</button>
        <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                <Modal.Title>Add User to {organisation?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {organisation?
                        <AddUserToOrganisationFrom 
                            organisation={organisation}                              
                            data={data}
                            closeModal={closeModal}
                            />              
                        :null
                    }
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
    </div>)
}


export default AddUserButton