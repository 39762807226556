import {FC, useState} from 'react'
import axios from 'axios'
import Messages from '../../HelperComponents/Messages'
import {Modal, Button} from 'react-bootstrap';
import InvoicePlans from '../../../Enums/InvoicePlans' 
import { validateEmail } from '../../HelperComponents/Functions';
interface Props 
{
    data:Data,
    getOrganisations: Function   
}

// interface Role {
//     id: number,
//     name: string
// }

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}


interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface FormData 
{
    name: string | null,
    email: string | null,
    planId: number | null     
}

const AddOrganisationFrom:FC<Props> = ({data, getOrganisations}) => 
{    
    const [name, setName] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [isExists, setIsExists] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([])
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [planId, setPlanId] = useState<number>(0);

    const clearForm = () =>
    {
        setName(null)
        setEmail(null)
        setPlanId(0)
    }
    function openModal() {       
        setIsOpen(true);
      }  
    function closeModal() {
        setIsOpen(false);
        getOrganisations()
        
    }


    const checkIfOrganisationExists = (name: string) => 
    {
        //check if email is already assigned to organisation
        axios.get('/api/organisation/CheckIfOrganisationExists/' + name, data.config)
        .then((response)=>
        {   
            setIsExists(response.data);
        })
    }
    
    const setOrganisationName = (name: string) =>
    {        
        checkIfOrganisationExists(name);    
        setName(name)
    } 

    const submit = async () => 
    {
        var currentErrors = []
        if(name === '' || name === null || name.length === 0 || isExists)
        {
            currentErrors.push("Invalid Name")

            if(isExists)
            {
                currentErrors.push("Name already exists")
            }
        }

        if(email === null || email.length === 0 || !validateEmail(email) || email === '')
        {
            currentErrors.push("Invalid Email")
        }

        if(planId === null)
        {
            currentErrors.push("Plan not selected")
        }
        

        var dataJSON:FormData =
        {
            name: name,
            email: email,
            planId: planId
        }
     
        setErrors(currentErrors)
        if(currentErrors.length > 0)
        {
            return
        }
        console.log(dataJSON)
        axios.post('/api/organisation/onboard',dataJSON, data.config)
        .then((response)=>
        {
            //getParents()
            clearForm()
            closeModal()
            setMessages(["Organisation Saved"])

            setTimeout(()=>
            {
                setMessages([])
               
            }, 3000)
        })      
    }

    return(
        <div id="AddUserOrganisation">
            <button className="btn btn-outline-info" onClick={()=>openModal()}>Add Organisation</button>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                <Modal.Title>Add Organisation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Messages messages={errors}/>
                    <Messages messages={messages}/>
                    <div id="AddUserOrganisation" className='modal-form'>
                        <div className='form-group'>
                            <label className='label'>Name</label>
                            <input value={name? name:""}   onChange={(e:React.FormEvent<HTMLInputElement>)=>setOrganisationName(e.currentTarget.value)} />
                        </div>
                        <div className='form-group'>
                            <label className='label'>Email</label>
                            <input value={email? email:""}   onChange={(e:React.FormEvent<HTMLInputElement>)=>setEmail(e.currentTarget.value)} />
                        </div>
                        <div className='form-group'>
                            <label className='label'>Invoice Plan</label>
                            <select value={planId? planId:""}   onChange={(e)=>setPlanId(parseInt(e.currentTarget.value))}>
                                {
                                    InvoicePlans.map((plan)=>
                                    (
                                        <option value={plan.id}>{plan.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={submit}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
                            )

}


export default AddOrganisationFrom;